// Libs
import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import flatten from 'lodash.flatten';
import styled from 'styled-components';

// Components
import Layout from 'components/layout';
import { device } from 'components/device';
import SEO from 'components/seo';
import BreadCrumb from 'components/breadCrumb';
import DescriptionPage from 'components/contactUs/descriptionPage';
import Contact from 'components/contactUs/contact';
import Menu from 'components/contactUs/menu';
import HeaderIntern from 'components/contactUs/headerIntern';

// Styles
const GridContainer = styled.div`
  display: grid;
  grid-template-columns: auto repeat(12, 70px) auto;
  column-gap: 30px;

  a,
  em {
    color: #45a7df;
  }

  em {
    font-style: normal;
    color: #8f9194;
  }

  code {
    margin-top: 1.375rem;
    margin-left: 2.8125rem;
    color: #8f9194;
    font-size: unset;
    font-family: unset;
    background: unset;
  }

  @media ${device.laptop} {
    display: block;
  }
`;

const Wrapper = styled.div`
  grid-column: 2 / -2;
`;

const ContainerContent = styled.div`
  display: inline-grid;
  grid-column: 5 / 11;
  flex-direction: column;

  p {
    em {
      margin-top: 15px;
      top: 0;
      display: inline-block;
      @media ${device.mobile} {
        margin-left: 44.8px;
        margin-top: -5px;
      }
    }
    img {
      :nth-child(1) {
        top: 1.1875rem;
        @media ${device.laptop} {
          top: 1.1875rem;
        }
      }

      :nth-child(2n) {
        position: relative;
        top: 6px;
        @media ${device.mobile} {
          top: 30px;
        }
      }
    }
  }

  section {
    p {
      :nth-child(3n) {
        img {
          position: relative;
          top: 3.4rem;
        }
      }
    }
  }

  @media ${device.laptop} {
    padding: 0 1.25rem;
    display: flex;

    p {
      width: 100%;
      :first-child {
        padding-bottom: 28px;
        @media ${device.mobile} {
          padding-bottom: 20px;
        }
      }
    }
  }
`;

const getMarkdown = (page, fragmentId, isMarkdown = false) => {
  const { fragments } = page[0];

  const findFragment = fragments.find(item => item.id === fragmentId);

  if (isMarkdown) {
    const { markdown } = flatten(findFragment.localizations)[0];
    return markdown;
  } else {
    return flatten(findFragment.localizations);
  }
};

const GuideCenter = ({ page, location }) => {
  const pathname = location.pathname;

  return (
    <Layout>
      <HeaderIntern />
      <SEO dataSeo={page} />
      <GridContainer>
        <Wrapper>
          <BreadCrumb
            markdown={getMarkdown(page, 'ckiacumj42buy0973le9lu71t', true)}
            marginTop
          />
        </Wrapper>
        <ContainerContent>
          <DescriptionPage
            markdown={getMarkdown(page, 'ckiad0ev42c5y0a77vih7gx3c', true)}
            widthLarge
          />
          <Contact
            markdown={getMarkdown(page, 'ckiad69ig2cge0962zbeh8vtp', true)}
            ombudsmanPage
          />
          <Menu intern pathname={pathname} />
        </ContainerContent>
      </GridContainer>
    </Layout>
  );
};

export default ({ location }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          gcms {
            site(where: { id: "ckgi8dg9k08b10a74cf20cxgk" }) {
              id
              pages(where: { id: "ckiacpe9c2bcx0a77uikoh4nj" }) {
                fragments {
                  id
                  name
                  localizations {
                    singletexts
                    markdown
                  }
                }
                seo {
                  metaTitle
                  metaDescription
                  noIndex
                  localizations {
                    metaTitle
                    metaDescription
                    noIndex
                  }
                }
              }
            }
          }
        }
      `}
      render={response => {
        return (
          <GuideCenter page={response.gcms.site.pages} location={location} />
        );
      }}
    />
  );
};
